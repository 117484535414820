import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
// Optional: If you need its default styles

function Demo2() {
  const imagescards = [
    "1.png",
    "2.png",
    "3.png",
    "4.png",
    "5.png",
    "6.png",
    "wedding1.jpg",
    "wedding2.jpg",
    "wedding3.png",
  ];

  const initialCards = [0, 1, 2];
  const firstRotation = [3, 4, 5];
  const secondRotation = [6, 7, 8];

  const [cardImages, setCardImages] = useState(initialCards);
  const [currentRotation, setCurrentRotation] = useState(0);
  const [isAnimating2, setIsAnimating2] = useState(false);

  const images = ["abc123.jpg", "b2.jpeg", "r123.png"];
  const texts = ["Marketing", "Production", "Wedding"];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const text2 = [
    "Elevate your success with the guidance of our seasoned experts.",
    "We build experience for brands.",
    "Leave your details and we will be in touch today.",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);
  const [currentText2Index, setCurrentText2Index] = useState(0);
  const [nextText2Index, setNextText2Index] = useState(1);

  const [exploreLink, setExploreLink] = useState("/Marketing");
  const navigate = useNavigate();

  // Reset rotation and link when the component mounts (i.e., when returning to the page)
  useEffect(() => {
    setCurrentRotation(0);
    setExploreLink("/Marketing");
    setCardImages(initialCards);
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  // Function to handle the "Explore Now" button click
  const handleExploreClick = () => {
    navigate(exploreLink);
  };

  const changeBackgroundAndText = () => {
    if (isAnimating || isAnimating2) return; // Prevent multiple clicks during animation

    setIsAnimating(true);
    setIsAnimating2(true);

    // Background and text animations
    setTimeout(() => {
      // Update background image index
      setCurrentImageIndex(nextImageIndex);
      setNextImageIndex((nextImageIndex + 1) % images.length);

      // Update text indices
      setCurrentTextIndex((currentTextIndex + 1) % texts.length);
      setCurrentText2Index(nextText2Index);
      setNextText2Index((nextText2Index + 1) % text2.length);

      setIsAnimating(false);
    }, 1000); // Duration of background and text slide animations

    // Card flip animation
    setTimeout(() => {
      if (currentRotation === 0) {
        setCardImages(firstRotation);
        setCurrentRotation(1);
        setExploreLink("/Production"); // Change to Production
      } else if (currentRotation === 1) {
        setCardImages(secondRotation);
        setCurrentRotation(2);
        setExploreLink("/Wedding"); // Change to Wedding
      } else {
        setCardImages(initialCards);
        setCurrentRotation(0);
        setExploreLink("/Marketing"); // Change back to Marketing
      }
      setIsAnimating2(false);
    }, 1000); // Duration for the card flip
  };

  return (
    <>
      <div className="app">
        {/* Background Images */}
        <div
          className={`background current ${
            isAnimating ? "slide-out-left" : ""
          }`}
          style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
        ></div>
        <div
          className={`background next ${isAnimating ? "slide-in-right" : ""}`}
          style={{ backgroundImage: `url(${images[nextImageIndex]})` }}
        ></div>

        <div className="leftSide">
          <div className="headBut">
            <div className="text-wrapper">
              <div
                className={`text current-text ${
                  isAnimating ? "slide-out-left" : ""
                }`}
                id="headText"
              >
                {texts[currentTextIndex]}
              </div>
              <div
                className={`text next-text ${
                  isAnimating ? "slide-in-right" : ""
                }`}
                id="headText2"
              >
                {texts[(currentTextIndex + 1) % texts.length]}
              </div>
              <div
                className={`text next-text2 ${
                  isAnimating ? "slide-in-right" : ""
                }`}
                id="headText3"
              >
                {texts[(currentTextIndex + 2) % texts.length]}
              </div>
            </div>
            <div className="button-wrapper">
              <button onClick={changeBackgroundAndText}>Next </button>
            </div>
          </div>
        </div>

        <div className="cards">
          {cardImages.map((image, index) => (
            <div
              key={index}
              className={`card ${isAnimating2 ? "rotate" : ""}`}
              style={{ backgroundImage: `url(${imagescards[image]})` }}
            ></div>
          ))}
        </div>
      </div>
      <div className="text-wrapper2">
        <div
          // className={`text current-text2 ${
          //   isAnimating ? "slide-out-left" : ""
          // }`}
          className="text current-text2"
        >
          {text2[currentText2Index]}
        </div>
      </div>
      <div className="explore-wrapper">
        {/* Trigger navigation on click */}
        <button className="explore-button" onClick={handleExploreClick}>
          Explore Now
        </button>
      </div>
    </>
  );
}

export default Demo2;

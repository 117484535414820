import React, { useEffect, useState } from "react";
import { useSprings, animated } from "react-spring";
import { useGesture } from "react-use-gesture";
import "./Marketing.css";
import "./Carousel.css";
import Splitting from "splitting";
import { Link } from 'react-router-dom';

const images = [
  "production1.png",
  "production2.png",
  "production3.png",
  "production4.png",
  "production5.png",
  "production6.png",
];
const Production = () => {
  const [index, setIndex] = useState(0);
  const angle = 360 / images.length;
  const radius = 300; // Increased radius for larger carousel

  const [springs, api] = useSprings(images.length, (i) => ({
    rotateY: i * angle,
    opacity: i === index ? 1 : 0.3,
    config: { mass: 5, tension: 500, friction: 80 },
  }));

  // Swipe detection using useGesture
  const bind = useGesture({
    onDrag: ({ movement: [mx], direction: [dx], velocity }) => {
      const trigger = velocity > 0.2;
      if (trigger) {
        setIndex((prev) =>
          dx < 0
            ? (prev + 1) % images.length
            : (prev - 1 + images.length) % images.length
        );
      }
    },
  });

  // Update springs whenever the index changes
  api.start((i) => ({
    rotateY: (i - index) * angle,
    opacity: i === index ? 1 : 0.5,
  }));

  const handlePrev = () => {
    setIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setIndex((prev) => (prev + 1) % images.length);
  };

  function openWhatsapp() {
    window.open(
      "https://wa.me/9711541313?text=Hello%20Auburn,%20I%20am%20interested%20in%20your%20marketing%20services",
      "_blank"
    );
  }

  function openInsta() {
    window.open("https://www.instagram.com/auburnstudio__/");
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    setIsLoaded(true); // Trigger animation when the component is loaded
    window.addEventListener("scroll", handleScroll);

    // Delay Splitting execution to ensure elements are rendered
    setTimeout(() => {
      Splitting();
    }, 100);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="marketing">
      <div className={`marketingIntro ${isLoaded ? "active" : ""}`}>
        {/* To add the slide up effect */}
        <nav>
        <Link to="/">
          <img src="logo.png" />
        </Link>
        </nav>
        <div
          className="marketingSubIntro"
          style={{
            transform: `translateY(${scrollY * 0.2}px)`, // Adjust scroll factor for a smoother effect
            opacity: scrollY > 100 ? 0 : 1, // Fades out after scrolling 100px
            transition: "transform 0.5s, opacity 0.5s",
          }}
        >
          <div className="marketingHeading">
            <div className="mark1Sub1">
              <p className="markHeading">Auburn Production LLP</p>
            </div>
          </div>
          <div className="marketingIntroHead">
            <div className="introHead1">
              1.2M
              <span className="introHead1a">CUSTOMERS</span>
            </div>
            <div className="introHead2">
              WE BUILD <br /> EXPERIENCES <br /> FOR BRANDS.
            </div>
          </div>
          <div className="marketingIntroDesc">
            <div className="introDesc1">
              <div className="introDesc1a">
                <button>CONNECT ↗</button>
              </div>
              <div className="introDesc1b">
                <p>
                  AUBURN is a premier digital marketing firm specializing in
                  comprehensive strategies, in-house content creation, and
                  innovative campaigns. With expertise in traditional marketing,
                  SEO, SEM, influencer marketing, and brand recognition, AUBURN
                  elevates businesses through tailored, impactful solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel">
          <div className="carousel-container" {...bind()}>
            {springs.map(({ rotateY, opacity }, i) => (
              <animated.div
                key={i}
                className="carousel-item"
                style={{
                  transform: rotateY.to(
                    (r) => `rotateY(${r}deg) translateZ(${radius}px)`
                  ),
                  opacity,
                  backgroundImage: `url(${images[i]})`,
                }}
              />
            ))}
          </div>
          <div className="carousel-controls">
            <button className="carousel-button left" onClick={handlePrev}>
              &#9664;
            </button>
            <button className="carousel-button right" onClick={handleNext}>
              &#9654;
            </button>
          </div>
        </div>
        <div className="services">
          <div className="servicesHead">
            <div className="servicesHeading">
              <p>SERVICES</p>
            </div>
            <div className="servicesTagline">
              <p>EXPERIENCE THE ART OF STORY TELLING</p>
            </div>
          </div>
          <div className="servicesDesc">
            <div
              style={{
                fontWeight: "lighter",
                fontSize: "1.2rem",
                paddingTop: "3rem",
              }}
            >
              <p>
                At Auburn, our journey exemplifies passion and innovation.{" "}
                <br /> From humble beginnings to a leading digital marketing{" "}
                <br /> powerhouse, our story is built on creativity, resilience,
                and <br /> excellence.
              </p>
            </div>
            <div style={{ fontWeight: "lighter", fontSize: "1.2rem" }}>
              <p>
                We transform visions into reality with data-driven <br />{" "}
                strategies and engaging in-house content. Traditional marketing{" "}
                <br /> channels and expert SEO and SEM techniques broaden <br />{" "}
                your reach and visibility.
              </p>
            </div>
            <div style={{ fontWeight: "lighter", fontSize: "1.2rem" }}>
              <p>
                Our influencer marketing amplifies your brand, while our <br />{" "}
                graphic design and ad campaigns leave lasting impressions.
              </p>
            </div>
          </div>
          <div className="servicesCards">
            <div className="servicesCards1">
              <div className="services1">
                {/* <img src="services-img.png" /> */}
                <h3 style={{ fontWeight: "lighter", color: "white" }}>
                  CREATIVE DIRECTION
                </h3>
              </div>
              <div className="services2">
                {/* <img src="services-img.png" /> */}
                <h3 style={{ fontWeight: "lighter", color: "white" }}>
                  360* SHOOTS
                </h3>
              </div>
              <div className="services1">
                {/* <img src="services-img.png" /> */}
                <h3 style={{ fontWeight: "lighter", color: "white" }}>
                  PRODUCT PHOTOGRAPHY
                </h3>
              </div>
              <div className="services2">
                {/* <img src="services-img.png" /> */}
                <h3 style={{ fontWeight: "lighter", color: "white" }}>
                  TALENTS ACQUISITION
                </h3>
              </div>
            </div>
            <div className="servicesCards2">
              <div className="services2">
                {/* <img src="services-img.png" /> */}
                <h3 style={{ fontWeight: "lighter", color: "white" }}>
                  MODELING
                </h3>
              </div>
              <div className="services1">
                {/* <img src="services-img.png" /> */}
                <h3 style={{ fontWeight: "lighter", color: "white" }}>
                  HAIR & MAKEUP
                </h3>
              </div>
              <div className="services2">
                {/* <img src="services-img.png" /> */}
                <h3 style={{ fontWeight: "lighter", color: "white" }}>
                  STYLING
                </h3>
              </div>
              <div className="services1">
                {/* <img src="services-img.png" /> */}
                <h3 style={{ fontWeight: "lighter", color: "white" }}>
                  VIDEOGRAPHY and PHOTOGRAPHY
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="videosMain">
          <div className="videosHead" id="case-studies">
            <div className="videosHeading">
              <span className="nav-co">
                Everything begins with <br /> to emotions
              </span>
            </div>
            <div className="videosTagline">
              Highlighting Our Most Impactful Projects, See the Success Stories
              We've Created
            </div>
          </div>
          <div className="videos">
            <div className="videos1">
              <div className="video-container">
                <div>
                  <video
                    className="small-video"
                    src="production3.mp4"
                    autoPlay
                    muted
                    loop
                    playsInline
                  ></video>
                </div>
                <div className="greys">
                  <p className="grey">CARTIER</p>
                  <p className="grey">ⓒ 2024</p>
                </div>
              </div>
              <div className="video-container">
                <video
                  className="small-video"
                  src="production1.mp4"
                  autoPlay
                  muted
                  loop
                  playsInline
                ></video>
                <div className="greys">
                  <p className="grey">SHOOT PRODUCTION</p>
                  <p className="grey">ⓒ 2024</p>
                </div>
              </div>
            </div>
            <div className="videos2">
              <div className="video-container">
                <video
                  className="small-video"
                  src="production2.mp4"
                  autoPlay
                  muted
                  loop
                  playsInline
                ></video>
                <div className="greys">
                  <p className="grey">FALSE PROMISES</p>
                  <p className="grey">ⓒ 2024</p>
                </div>
              </div>
              <div className="video-container">
                <video
                  className="small-video"
                  src="production4.mp4"
                  autoPlay
                  muted
                  loop
                  playsInline
                ></video>
                <div className="greys">
                  <p className="grey">THE MASQUERADED FEAST</p>
                  <p className="grey">ⓒ 2024</p>
                </div>
              </div>
            </div>
          </div>
          <div className="case">
            <button onClick={openInsta}>ALL CASE STUDIES</button>
          </div>
          <div className="contact" id="contact">
            <div className="contact1">
              <p>Let's shape & build your brand together</p>
            </div>
            <div className="contact2">
              <button onClick={openWhatsapp}>Let's Talk</button>
            </div>
          </div>
          <div className="footer">
            <div>
              <div>
                <h1>Auburn</h1>
              </div>
              <div>
                <p className="grey">ⓒ Copyright 2024. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Production;

import React from "react";
import ReactDOM from "react-dom/client"; // Import the new 'react-dom/client'
import "./index.css";
import App from "./App";
import Carousel3D from "./Demo";

// Create the root and render the App component
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
